import { Suspense, lazy } from 'react';
import MapLoadingOverlay from '@components/map/MapLoadingOverlay';

const Map = lazy(() => import('@components/map/InteractiveMap'));

export default function OrganizationMap(props) {
  const {
    cache,
    organizations,
    defaultZoom,
    onItemClick,
    onStateChange,
    loading,
  } = props;

  return typeof window !== 'undefined' && (
    <Suspense fallback={<MapLoadingOverlay visible />}>
      <Map
        cache={cache}
        config={MAP_CONFIG}
        defaultZoom={defaultZoom}
        loading={loading}
        onItemClick={onItemClick}
        onStateChange={onStateChange}
        projects={organizations}
      />
      <MapLoadingOverlay visible={loading} />
    </Suspense>
  );
}

const MAP_CONFIG = {
  clusterMarkerRadius: 44,
  markerRadius: 28,
};
