import {
  Box,
  CircularProgress,
} from '@mui/material';

export default function MapLoadingOverlay({ visible }) {
  return (
    <Box
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        opacity: visible ? 1 : 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        transition: 'opacity 0.25s ease-in-out',
      }}
    >
      <CircularProgress variant={visible ? 'indeterminate' : 'determinate'} />
    </Box>
  );
}
