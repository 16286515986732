import Head from 'next/head';
import MainLayout from '@components/layouts/MainLayout';
import FilterBar from '@modules/organizations/components/FilterBar';
import SplitView from '@modules/organizations/components/SplitView';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import CompactOrganizationList from '@modules/organizations/components/CompactOrganizationList';
import ExtendedFilters from '@modules/organizations/components/ExtendedOrgFilters';
import OrganizationMap from '@modules/organizations/components/OrganizationMap';
import isEqual from 'lodash/isEqual';
import {
  QUERY_FILTER_PERSIST,
  QUERY_TYPE,
  useQueryFilter,
} from '@lib/hooks';
import {
  gql,
  useQuery,
} from '@apollo/client';
import {
  graphQlPaginationMetaProps,
  usePagination,
} from '@lib/hooks/usePagination';
import ViewContainer from '@modules/organizations/components/ViewContainer';
import ListContainer from '@modules/organizations/components/ListContainer';
import MapContainer from '@modules/organizations/components/MapContainer';
import {
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useOrganizationQueryFilters } from '@modules/organizations/hooks';
import OrganizationDetailsPage, { useOrganizationDetailsPage } from '@modules/organizations/components/organization-details/OrganizationDetailPage';
import { useSelector } from '@store';
import { selectCurrentTagRoot } from '@modules/organizations/state/slices/organization-tag-root';
import MapLegend from '@modules/organizations/components/MapLegend';
import { DEFAULT_VIEW_MOBILE } from '@modules/projects/config';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ORGANIZATION_TYPES } from '@modules/organizations/config';
import { getGraphQlSchemaForTags } from '@modules/projects/data/tags';
import { usePaginationReset } from '@lib/hooks/usePaginationReset';
import { MAP_EMPTY_ID_COLLECTION } from '@config';

export default function MapList() {
  const { i18n } = useTranslation();
  const { tag } = useSelector(selectCurrentTagRoot);
  const { openOrganizationDetailsPage } = useOrganizationDetailsPage();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isInitialState, setInitialState] = useState(true);
  const [mapCache, setMapCache] = useState({});
  const [orgIdsInView, setOrgIdsInView] = useState(MAP_EMPTY_ID_COLLECTION);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useQueryFilter('xos', '');
  const [organizationTypeFilter, setOrganizationTypeFilter] = useQueryFilter('xot', DEFAULT_ORGANIZATION_TYPES, QUERY_FILTER_PERSIST.default, QUERY_TYPE.array);
  const [organizationFilters, setOrganizationFilters] = useQueryFilter('xof', {});
  const [showLiquidatedOrgsFilter, setLiquidatedOrgsFilter] = useQueryFilter('xol', false);

  useEffect(() => {
    if (isMobile) {
      // Route user to default view if it is on mobile and opens this page
      router.push(`/organizations/${DEFAULT_VIEW_MOBILE}`);
    }
  }, [router, isMobile]);

  const {
    fetchProps,
    mergePaginationProps,
    resetPagination,
  } = usePagination({ pageSize: 25 });
  const queryFilters = useOrganizationQueryFilters(searchTerm, {
    filter: {
      organizationFilters,
      organizationTypeFilter,
      showLiquidatedOrgsFilter,
    },
  });
  const { data: tagsData } = useQuery(FETCH_TAGS, {
    variables: { tag },
  });
  const { data, loading: mapLoading } = useQuery(FETCH_MAP, {
    fetchPolicy: 'network-only',
    variables: {
      tag,
      filters: queryFilters,
    },
    onCompleted: () => {
      setInitialState(false);
      setMapCache({});
      window.dispatchEvent(new CustomEvent('b:map:refresh-state'));
    },
  });
  const { data: itemsData, loading: organizationsLoading } = useQuery(FETCH_ITEMS, {
    variables: {
      ...fetchProps,
      sort: ['index1:asc'],
      filters: {
        id: { in: orgIdsInView },
      },
      locale: i18n.language,
      tag,
    },
  });
  usePaginationReset(
    resetPagination,
    fetchProps.pagination.pageSize,
    queryFilters,
  );

  const organizations = itemsData?.calculatedOrganizations.data ?? [];
  const mapOrganizations = data?.mapOrganizations.data ?? [];
  const mapLegend = itemsData?.organizationLegend ?? {};
  const organizationStatistics = itemsData?.organizationStatistics ?? {};
  const tagItems = tagsData?.tagItems.data ?? [];

  const handleMapStateChange = useCallback((state, { ids }) => {
    if (!isEqual(orgIdsInView, ids)) {
      setOrgIdsInView(ids.length === 0 ? MAP_EMPTY_ID_COLLECTION : ids);
      resetPagination();
    }
  }, [orgIdsInView, resetPagination]);

  const handleOpenOrganization = (id) => {
    openOrganizationDetailsPage(id, 'maplist');
  };

  return (
    <>
      <Head>
        <title>
          BecoSearch Organisationen
        </title>
      </Head>
      <ViewContainer>
        <FilterBar
          currentView="maplist"
          isFilterOpen={isFilterOpen}
          loading={organizationsLoading}
          organizationFilters={organizationFilters}
          organizationTypeFilter={organizationTypeFilter}
          search={searchTerm}
          setFilterOpen={setFilterOpen}
          setLiquidatedOrgsFilter={setLiquidatedOrgsFilter}
          setOrganizationFilters={setOrganizationFilters}
          setOrganizationTypeFilter={setOrganizationTypeFilter}
          setSearchTerm={setSearchTerm}
          showLiquidatedOrgsFilter={showLiquidatedOrgsFilter}
          statistics={organizationStatistics}
        />
        <Divider />
        <SplitView
          left={(
            <ListContainer>
              <CompactOrganizationList
                {...mergePaginationProps(itemsData?.calculatedOrganizations.meta)}
                loading={isInitialState || organizationsLoading}
                onItemClick={handleOpenOrganization}
                organizations={organizations}
                tagItems={tagItems}
              />
            </ListContainer>
          )}
          right={(
            <MapContainer>
              <OrganizationMap
                cache={mapCache}
                defaultZoom={6}
                loading={mapLoading}
                onItemClick={handleOpenOrganization}
                onStateChange={handleMapStateChange}
                organizations={mapOrganizations}
              />
              <MapLegend legend={mapLegend} />
            </MapContainer>
          )}
        />
      </ViewContainer>
      <ExtendedFilters
        isFilterOpen={isFilterOpen}
        organizationFilters={organizationFilters}
        setFilterOpen={setFilterOpen}
        setOrganizationFilters={setOrganizationFilters}
      />
      <OrganizationDetailsPage />
    </>
  );
}

MapList.getLayout = (page) => (
  <MainLayout>
    {page}
  </MainLayout>
);

const FETCH_TAGS = gql`
  query Fetch($tag: String!) {
    tagItems(
      sort: ["index", "name_de"],
      filters: {
        referenceId: {eq: $tag}
      }
    ) {
      data {
        ${getGraphQlSchemaForTags()}
      }
    }
  }`;

const FETCH_MAP = gql`
  query Fetch($filters: OrganizationFiltersInput!, $tag: String!) {
    mapOrganizations(filters: $filters, tag: $tag) {
      data {
        id
        attributes {
          latitude
          longitude
          segments {
            id
            name
            color
            index
          }
        }
      }
    }
  }`;

const FETCH_ITEMS = gql`
  query Fetch(
    $locale: String!,
    $filters: OrganizationFiltersInput!,
    $tag: String!,
    $pagination: PaginationArg,
    $sort: [String]!
  ) {
    calculatedOrganizations(
      sort: $sort,
      filters: $filters,
      pagination: $pagination,
      locale: $locale,
      template: "compact",
    ) {
      data
      meta {
        ${graphQlPaginationMetaProps}
      }
    }
    organizationLegend(filters: $filters, tag: $tag, locale: $locale) {
      segments {
        id
        name
        color
        value
      }
    }
    organizationStatistics(filters: $filters) {
      total
      locations
    }
  }`;
